(function () {
  const widgets = document.querySelectorAll(".js-serviceLister");

  widgets.forEach(function (widget) {
    const selectors = widget.querySelectorAll(".js-serviceListerSelector");
    const contents = widget.querySelectorAll(".js-serviceListerContent");

    selectors.forEach(function (selector, idx) {
      selector.addEventListener("click", function () {
        setActivePanel(idx);
      });
    });

    function setActivePanel(activeIndex) {
      contents.forEach(function (content, idx) {
        if (idx === activeIndex) {
          content.classList.add("active");
        } else {
          content.classList.remove("active");
        }
      });

      selectors.forEach(function (selector, idx) {
        if (idx === activeIndex) {
          selector.classList.add("active");
        } else {
          selector.classList.remove("active");
        }
      });
    }
  });
})();
